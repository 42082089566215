<template>
  <div>
    <SButtonCreateOrder class="mb-3" to="shipment/new" />
    <TTableAdvance
      :items="shipment"
      :fields="fields"
      store="order.order_shipment"
      @click-clear-filter="clearFilter"
      resource="/sale/orders/shipment"
      enterable
      deletable
      reloadable
      @click-reload="refresh"
    >
      <template #trackings="{ item }">
        <td>
          <SMessageTracking
            v-if="item.trackings.length > 0"
            :value="item.trackings[0].code"
            :checked="item.trackings[0].received"
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #status="{ item }">
        <td>
          <SMessageOrderStatus :id="item.status_id" />
        </td>
      </template>
      <template #customer_id="{ item }">
        <td>
          <TLink
            v-if="item.customer"
            :to="lodash.getReferenceLink('user', item.customer_id)"
            :content="item.customer.name"
          />
        </td>
      </template>
      <template #shipment_method_id="{ item }">
        <td>
          <SMessageShipmentMethod :id="item.shipment_method_id" />
        </td>
      </template>
      <template #note="{ item }">
        <td>
          <TMessage :content="item.note" noTranslate />
        </td>
      </template>
      <template #updated_at="{ item }">
        <td>
          <TMessageDateTime :content="item.updated_at" small />
        </td>
      </template>
      <template #id-filter>
        <TInputText
          :value.sync="filter.id"
          @update:value="filterChange"
          placeholder="Order Id"
        />
      </template>
      <template #customer_id-filter>
        <SSelectCustomer
          :value.sync="filter.customer_id"
          @change="filterChange"
          noCustomLabel
          prependAll
        />
      </template>
      <template #trackings-filter>
        <TInputText
          :value.sync="filter['by_tracking_code']"
          @update:value="filterChange"
          placeholder="Tracking Id"
        />
      </template>
      <template #shipment_method_id-filter>
        <SSelectShipmentMethod
          :value.sync="filter.shipment_method_id"
          @change="filterChange"
        />
      </template>
      <template #status-filter>
        <SSelectOrderStatus
          class="text-truncate-0"
          store="order.shipment_statuses"
          :value.sync="filter.status_id"
          @change="filterChange"
        />
      </template>
      <template #updated_at-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setUpdatedAtFilter"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "id",
          label: "Order Id",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "trackings",
          label: "Tracking Id",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "status",
          label: "Status",
          _classes: "",
          _style: "width: 240px; min-width: 240px",
        },
        {
          key: "customer_id",
          label: "Customer",
          _classes: "text-nowrap",
          _style: "min-width: 250px",
        },
        {
          key: "shipment_method_id",
          label: "Shipment method",
          _classes: "text-nowrap",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "note",
          label: "Note",
          _style: "width: 270px; min-width: 270px",
        },
        {
          key: "updated_at",
          sorter: true,
          label: "Updated at",
          _style: "width: 220px",
        },
      ],
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  mounted() {
    this.$store.dispatch("order.order_shipment.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      shipment: "order.order_shipment.list",
    }),
  },
  methods: {
    refresh() {
      this.$store.dispatch("order.order_shipment.fetch");
    },
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({ ...filter, ...filterBetween });
      this.$store.dispatch("order.order_shipment.apply-query", filterFields);
    },
    setUpdatedAtFilter({ start, end }) {
      this.filter_between["updated_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filter = {};
      this.filter_between = {};
      this.filterChange();
    },
  },
};
</script>
